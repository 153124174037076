import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authRequest } from '../../../api/auth.js';
import { useUsedFolderUUID } from './currentFolderUUID';
import { useSelector } from 'react-redux';

const getFolderPath = async ({ folderUUID }) => {
  const pathUrl = `/api/datamanage/path/folder/${folderUUID}`;
  return await authRequest.get(pathUrl).then(res => res.data);
};

export const useFolderPath = ({
  folderUUID = '',
  useErrorBoundary = undefined,
} = {}) => {
  const usedFolderUUID = useUsedFolderUUID(folderUUID);

  return useQuery({
    queryKey: ['folderPath', usedFolderUUID],
    queryFn: () => getFolderPath({ folderUUID: usedFolderUUID }),
    enabled: !!usedFolderUUID && usedFolderUUID !== '', // folder uuid が存在するまで待機
    placeholderData: [], // initialDataとは違いキャッシュに残らない
    useErrorBoundary,
    networkMode: 'always', // Forces the query to run even offline
  });
};

export function useFolderPathInvalidate({ folderUUID = '' } = {}) {
  const usedFolderUUID = useUsedFolderUUID(folderUUID);
  const queryClient = useQueryClient();
  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(['folderPath', usedFolderUUID]);
  }, [queryClient, usedFolderUUID]);
  return invalidateQueries;
}

export function useFolderPathReset() {
  const queryClient = useQueryClient();
  const currentFolderUUID = useSelector(
    state => state.storageManager.currentFolderUUID
  );

  const resetQueries = useCallback(
    ({ folderUUID = '' } = {}) => {
      if (folderUUID === '') {
        queryClient.resetQueries(['folderPath', currentFolderUUID]);
      } else {
        queryClient.resetQueries(['folderPath', folderUUID]);
      }
    },
    [currentFolderUUID, queryClient]
  );
  return resetQueries;
}
