import React, {
  useEffect,
  useState,
} from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { useOrgDetail } from '../../../organization/api/getOrgDetail';
import { setOrgLastUrl } from '../../../../utils/orgLastUrl';

import DrawerRootFolder, { NewStorageMenuButton } from '../DrawerRootFolder';
import ConferenceHeader from './ConferenceHeader';
import {
  getConference, editCaseStudy, getFolder
} from '../../../../api/rest';
import { CONFERENCE_APP_TITLE } from './ConferenceTitle';

import {
  Typography, Button, TextField, Breadcrumbs, Switch, FormControlLabel,
  Link, Paper, Chip, Grid, Input, Toolbar
} from '@material-ui/core';
import 'date-fns';
import CopyToClipBoard from 'react-copy-to-clipboard';

import { CheckedItemToolbar } from '../DataViewer/AgGridTableToolbar';
import { authRequest, userLoggedIn } from '../../../../api/auth';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  conferenceContainer: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  flexLeft: {
    flex: 1,
  },
  flexRight: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    marginLeft: 'auto',
    minWidth: `200px`,
  },
  conferenceText: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  breadcrumb: {
    marginLeft: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '800px',
    padding: theme.spacing(2), // Add spacing around the form
    margin: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
  },
  button: {
    borderRadius: '10px',
    margin: theme.spacing(1),
  },
}));

const CaseStudy = ({accessDenied, setAccessDenied}) => {
  // Details for a single case study within a conference
  const classes = useStyles();
  // saved status for CaseStudy form
  const [saved, setSaved] = useState(false);
  const [caseStudyName, setCaseStudyName] = useState('');
  const [comment, setComment] = useState('');
  const [conferenceName, setConferenceName] = useState('');

  // Flag for showing Case Study dialog
  const [open, setOpen] = useState(false);

  // Flag for editing CaseStudy details
  const [editing, setEditing] = useState(false);
  const [publicUrl, setPublicUrl] = useState(false);
  // Flag is true if user is anonymous (not logged in)
  // If false, show additional content including breadcrumbs
  const [anonUser, setAnonUser] = useState(true);

  // Get user's default org if not set.
  const { data: orgDetails } = useOrgDetail();
  const { initialOrgId, parentUUID, folderUUID } = useParams();

  // Set anonymous upload permissions
  const changeUploadPerm = async (newPerm, expiryDate) => {
    try {
      console.log('newPerm', newPerm, 'expiryDate', expiryDate);
      // Dont use changeAnonymousPermApi, need /api/datamanage/perm/anonymous-folder/
      let url = `/api/datamanage/perm/anonymous-folder/${folderUUID}`;
      await authRequest.patch(url, { perm: newPerm, anon_link_expiry: expiryDate });
    } catch (err) {
      console.error('changeUploadPerm', err);
      if ('detail' in err.response.data) {
        alert(err.response.data['detail']);
      } else {
        throwError(err);
      }
    }
  }

  const handlePublicUrl = async () => {
    // Public URL toggle was clicked.
    // Save anon perm for the opposite of current state
    const newPublicUrl = !publicUrl;
    // In both cases, expiry date is null
    if (newPublicUrl === true) {
      // if newPublicUrl is true, set the 'can_edit' perm
      await changeUploadPerm('full_access', null);
      setPublicUrl(newPublicUrl);
    } else {
      // if newPublicUrl is false, set the 'delete' perm
      await changeUploadPerm('delete', null);
      setPublicUrl(newPublicUrl);
    }
  }

  // Copy URL button was clicked
  const onCopyURL = () => {
    const message = 'URLはコピーされました';
    toast.info(message);
  }

  const handleEditing = () => {
    setEditing(true);
    setOpen(true);
    setSaved(false);
  }

  const handleSave = async () => {
    console.log('Save CaseStudy');
    // User tried to save a CaseStudy, validate here
    setSaved(true);
    if (caseStudyName.trim() === '') {
      console.log('CaseStudy form is incomplete!');
      return;
    }
    try {

      const data = { name: caseStudyName, comment: comment }
      console.log('EDIT existing CaseStudy, data', data, 'folder', folderUUID);
      const caseStudyData = await editCaseStudy(folderUUID, data);
      setEditing(false);

    } catch (error) {
      console.log('Bad conference data');
    }
  }

  const handleCancel = () => {
    setEditing(false);
    setSaved(false);
  }

  // useEffect to initialize this CaseStudy
  useEffect(() => {
    async function initCaseStudy() {
      /**
       * First check if user is logged in. We expect that this folder
       * may be accessed by Conference participant who has never
       * registered on S9, but needs to have upload access (if permitted).
       * These users will appear as AnonymousUser in backend.
       *  */
      try {
        const isLoggedIn = await userLoggedIn();
        setAnonUser(!isLoggedIn);
        if (isLoggedIn) {
          // Get name of the parent Conference for Breadcrumbs, if logged in
          try {
            const conferenceInfo = await getConference(parentUUID);
            if (Object.keys(conferenceInfo).length > 0) {
              // Set conference name from saved data
              setConferenceName(conferenceInfo.name);
            }
          } catch (error) {
            console.error('Error getting info for parent Conference', error);
          }
        }
      } catch (error) {
        console.error('Error getting login status', error);
      }

      try {
        // Get details for this CaseStudy
        const caseStudyInfo = await getConference(folderUUID);
        console.log('This caseStudyInfo', caseStudyInfo);
        if (Object.keys(caseStudyInfo).length > 0) {
          // Correctly Show status of public URL
          if (caseStudyInfo.anonymous_perm == "full_access") {
            setPublicUrl(true);
          } else {
            setPublicUrl(false);
          }
          setCaseStudyName(caseStudyInfo.name);
          document.title = caseStudyInfo.name + ' | ' + CONFERENCE_APP_TITLE;
          setComment(caseStudyInfo.comment);
          // Set accessDenied false to display content
          setAccessDenied(false);
        }

      } catch (error) {
        console.error('ERROR initCaseStudy', error);
        const statusCode = error.response.status;
        console.error('initCaseStudy status', statusCode);
        // Check if the status code is in the 40x or 50x range
        if (statusCode >= 400 && statusCode < 600) {
          // Show access denied page
          setAccessDenied(true);
        }
      }
    }
    initCaseStudy();
  }, []);

  // URLs for Breadcrumbs component
  const conferenceListUrl = `/conference/org/${initialOrgId}`;
  const conferenceUrl = `${conferenceListUrl}/event/${parentUUID}`;

  console.log('Case Study', caseStudyName, 'anonUser', anonUser);

  const handleDragOver = (event) => {
    // Prevent default dragOver behavior to allow dropping
    // https://stackoverflow.com/a/50233827
    event.preventDefault();
  };

  // Handle drag-and-drop (file move) action over a breadcrumb
  const handleDrop = (event, targetBreadcrumb) => {
    event.preventDefault();
    const data = JSON.parse(event.dataTransfer.getData('application/json'));
    console.log('TODO: onDrop', data);
    // onDrop(data, targetBreadcrumb);  // Call the onDrop function with the dropped data
  };

  // Hey Copilot: write a function here to test for prime numbers
  // and return true if the number is prime, false if it isn't.


  // TODO: if user is not logged in (anon uploader), show only the Upload button and file manager view (list of files). For top bar, don't show Org (?)
  return (
    <div>
      <ConferenceHeader />
      {/* Render CaseStudy if access is allowed */}
      {(
        accessDenied === null ? (<div></div>) :
        accessDenied === false ? (
          <div>
            {!editing ? (
              <div className={classes.conferenceContainer}>
                <div className={classes.flexLeft}>
                  { !anonUser && (
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                      <Link color="inherit" href={conferenceListUrl}>
                        {orgDetails.name}
                      </Link>
                      <Link
                        className="file-drag-target"
                        id={parentUUID}
                        color="inherit"
                        href={conferenceUrl}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        {conferenceName}
                      </Link>
                      <Typography color="textPrimary">{caseStudyName}</Typography>
                    </Breadcrumbs>
                  )}
                  <div className={classes.conferenceText}>

                    <Typography variant="h6">
                      {caseStudyName}
                    </Typography>
                    <Typography>{comment}</Typography>
                    { !anonUser && (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleEditing}
                          className={classes.button}
                        >
                          編集
                        </Button>

                        <CheckedItemToolbar orgId={orgDetails.uuid} />
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.flexRight}>
                  {/* Data Upload button */}
                  <NewStorageMenuButton />
                  { !anonUser && (
                    <div>
                      {/* Toggle for allowing anon uploads, only visible to logged-in users */}
                      <Typography>
                        アップロード専用URLを有効する
                      </Typography>
                      <Switch
                        checked={publicUrl}
                        onClick={handlePublicUrl}
                        name="publicUrlCheckbox"
                        color="primary"
                      />
                      {/* Show copy to clipboard button if URL is public */}
                      { publicUrl && (
                        <div>
                          <CopyToClipBoard
                            text={window.location.href}
                            onCopy={onCopyURL}  // Show a toast
                          >
                            <Button
                              variant="contained"
                              disabled={!publicUrl}
                            >
                              URLをコピー
                            </Button>
                          </CopyToClipBoard>
                        </div>
                      )}
                    </div>
                  )}

                </div>
                {/* Warning message about publicUrl */}
                {/* { publicUrl && (
                  <div>
                    <Typography>
                      URLをコピーするとこちらの症例にアップロードするつもり相手と共有してください。
                    </Typography>
                    <Typography>
                      ※URLが知っていると誰でも症例のデータをアクセス・編集できるので、要注意。
                    </Typography>
                  </div>
                )} */}
              </div>
            ) : (
              <div className={classes.form}>

                <form>
                  <TextField
                    label="症例の名前"
                    placeholder="例：【出展】症例１中枢神経領域"
                    value={caseStudyName}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setCaseStudyName(e.target.value)}
                    className={classes.textField}
                    required={true}
                    error={!caseStudyName && saved} // Set to true if name is empty
                    helperText={!caseStudyName && "名前は必須です"}
                  />
                  <TextField
                    label="症例の内容"
                    placeholder="例：〇〇先生（〇〇病院）"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={2}
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    className={classes.textField}
                    required={true}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    className={classes.button}
                  >
                    保存
                  </Button>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={handleCancel}
                    className={classes.button}
                  >
                    キャンセル
                  </Button>
                </form>
              </div>
            )}
          </div>
        ) :
        (<div></div>)
      )}
    </div>
  );
}

export default CaseStudy;
