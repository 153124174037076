import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ModalContext } from '../../../context/ModalContext';
import {
  checkFullAccess,
  checkCanEdit,
  checkCanComment,
  checkCanView,
} from '../../../utils/checkAccessPerm';
import { useFetchUpdateInfo } from '../api/fetchUpdateInfo';
import { bite2XB, DateExtract } from '../../../utils/dataProcess';

import CopyToClipBoard from 'react-copy-to-clipboard';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CloseIcon from '@material-ui/icons/Close';

import { TagsSelect } from './tags/TagsSelect';
import { useSelectData } from '../utils/useSelectData';
import { isConferencePage } from './Conference/StorageConference';

const useStyles = makeStyles(theme => ({
  drawerInfo: {
    width: theme.drawerInfoWidth,
    flexShrink: 0,
  },
  drawerPaperInfo: {
    width: theme.drawerInfoWidth,
    backgroundColor: theme.color.bgColorBase,
  },
  card: {
    height: '100%',
    backgroundColor: theme.color.bgColorBase,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  tagsAction: {
    padding: theme.spacing(1, 0),
  },
  typography: {
    padding: theme.spacing(1),
  },
  toolbar: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 1),
  },
  drawerTitle: {
    fontSize: '1.2rem',
    paddingTop: theme.spacing(1),
    color: theme.color.textColorBase,
  },
  drawerTypography: {
    fontSize: '0.8rem',
    color: theme.color.textColorBase,
  },
  drawerContent: {
    // 上下padding
    padding: theme.spacing(1, 0),
  },
  drawerBox: {
    padding: theme.spacing(0, 2, 1, 2),
  },
  listTitle: {
    width: '80px',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  listText: {
    width: theme.drawerInfoWidth - 80 - theme.spacing(2),
    fontSize: '0.8rem',
    color: theme.palette.text.primary,
  },
  menuButton: {},
}));

const DrawerInfoContent = ({ handleDrawerInfoToggle }) => {
  const classes = useStyles();
  const selectData = useSelector(state => state.storageManager.selectData);
  const [openTip, setOpenTip] = React.useState(false);
  const { openShareModal } = useContext(ModalContext);
  const fetchUpdateInfo = useFetchUpdateInfo();
  const systemPropsList = [
    { title: 'タイプ', value: selectData.data.type },
    { title: 'サイズ', value: bite2XB(selectData.data.size) },
    // { title: '更新日時', value: DateExtract(selectData.data.updated_dt) },
    { title: '作成日時', value: DateExtract(selectData.data.created_dt) },
  ];
  const isConference = isConferencePage();

  const [viewerUrl, setViewerUrl] = React.useState('');
  useEffect(() => {
    if (selectData && selectData.data && selectData.data.uuid) {
      setViewerUrl(
        `/viewer?url=/api/datamanage/dcm/${selectData.type}/${selectData.data.uuid}`
      );
    }
  }, [selectData]);

  return (
    <>
      <Box className={classes.toolbar}>
        <Box p={'3px'}>
          {selectData.type == 'folder' ? (
            <FolderOutlinedIcon />
          ) : (
            <DescriptionOutlinedIcon />
          )}
        </Box>
        <Typography variant="h6" component="h3">
          {selectData.data.name}
        </Typography>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerInfoToggle}
          className={classes.menuButton}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box className={classes.drawerContent}>
        {/* 権限 */}
        <Box className={classes.drawerBox}>
          {/* タイトル */}
          <Typography
            variant="h6"
            component="h3"
            className={classes.drawerTitle}
          >
            あなたの権限
          </Typography>
          {/* 内容 */}
          <Typography
            variant="h6"
            component="h3"
            className={classes.drawerTypography}
          >
            {checkFullAccess(selectData.data.perm)
              ? 'フルアクセス権限'
              : checkCanEdit(selectData.data.perm)
              ? '編集権限'
              : checkCanComment(selectData.data.perm)
              ? 'コメント権限'
              : checkCanView(selectData.data.perm)
              ? '閲覧権限'
              : '権限なし'}
          </Typography>
          <Button
            color="primary"
            onClick={e =>
              openShareModal({
                data: selectData.data,
                funcWhenCompleted: () => async () => {
                  await fetchUpdateInfo();
                },
              })
            }
          >
            アクセス権限を管理
          </Button>
        </Box>
        <Box className={classes.drawerBox}>
          {/* オーナー */}
          <Typography
            variant="h6"
            component="h3"
            className={classes.drawerTitle}
          >
            システムプロパティ
          </Typography>
          <List disablePadding dense>
            {systemPropsList.map((prop, index) => (
              <ListItem disableGutters key={index}>
                <Typography className={classes.listTitle}>
                  {prop.title}
                </Typography>
                <Typography className={classes.listText}>
                  {prop.value}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className={classes.drawerBox}>
          {/* タグ */}
          {selectData.data.org != null && (
            <>
              <Typography
                variant="h6"
                component="h3"
                className={classes.drawerTitle}
              >
                タグ
              </Typography>
              <TagsSelect data={selectData.data} />
            </>
          )}
        </Box>

        <Box className={classes.drawerBox}>
          {((selectData.type == 'folder' &&
            selectData.data.foldertype == 'study') ||
            (selectData.type == 'folder' &&
              selectData.data.foldertype == 'series')) && (
            <>
              {!isConference && (
                <div>
                  <Typography
                    variant="h6"
                    component="h3"
                    className={classes.drawerTitle}
                  >
                    ビューアーを開く
                  </Typography>
                  <Button
                    color="primary"
                    onClick={() => window.open(viewerUrl, '_blank')}
                  >
                    画像を開く
                  </Button>
                </div>
                )}
              <Tooltip
                arrow
                open={openTip}
                onClose={() => setOpenTip(false)}
                disableHoverListener
                placement="top"
                title="Copied!"
              >
                <CopyToClipBoard text={window.location.origin + viewerUrl}>
                  <IconButton color="primary" onClick={() => setOpenTip(true)}>
                    <AssignmentOutlinedIcon />
                  </IconButton>
                </CopyToClipBoard>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default function DrawerInfo(props) {
  const classes = useStyles();
  const selectData = useSelectData();
  if (selectData.data.name) {
    console.log('Displaying contents of:', selectData.data.name);
  }

  const { openDrawerInfo, setOpenDrawerInfo } = useContext(ModalContext);

  const handleDrawerInfoToggle = () => {
    setOpenDrawerInfo(open => !open);
  };

  return (
    <Drawer
      className={classes.drawerInfo}
      variant={props.variant}
      anchor="right"
      open={openDrawerInfo}
      classes={{
        paper: classes.drawerPaperInfo,
      }}
    >
      <Toolbar />
      {!(selectData && selectData.data) ? (
        <></>
      ) : Object.keys(selectData.data).length === 0 ? (
        <>
          <Box className={classes.toolbar}>
            <Typography variant="h6" component="h3">
              データ未選択
            </Typography>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerInfoToggle}
              className={classes.menuButton}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </>
      ) : (
        <DrawerInfoContent handleDrawerInfoToggle={handleDrawerInfoToggle} />
      )}
    </Drawer>
  );
}

DrawerInfo.propTypes = {};
