import { ORG_LAST_URL } from '@ohif/viewer/src/utils/localStorageKeys';
import { getOrgPermBool } from '../api/rest';

export function setOrgLastUrl() {
  const lastUrl = location.pathname;
  console.debug('setOrgLastUrl', lastUrl);
  window.localStorage.setItem(ORG_LAST_URL, lastUrl);
}

export function removeOrgLastUrl() {
  window.localStorage.removeItem(ORG_LAST_URL);
}

export function getOrgIdFromUrl(url) {
  /**
   * Extract the Org ID from a S9 URL.
   * e.g. /storage/org/8d38a314-062d-4873-b6fb-340dfa92a5ed/tutorial/1
   * Do not require a trailing slash in the URL.
   */
  const regex = /\/org\/([0-9a-fA-F-]+)/;
  const match = url.match(regex);
  const orgId = match ? match[1] : null;
  console.log('getOrgIdFromUrl', orgId);
  return orgId;
}

function lastUrlNotEmpty(lastUrl){
  const isValidUrl = (typeof lastUrl === 'string') && (lastUrl.length > 2);
  console.log('OrgLastUrl Exists:', isValidUrl);
  return isValidUrl;
}

export async function getOrgLastUrl() {
  /**
   * A valid last Orl URL is a UUID for an org to which the user has access.
   * If any error checks fail, return null.
   * If all error checks pass, return the URL string, e.g.
   * /storage/org/<org-uuid>/root/private
   *
   * Since we have validated this URL, the calling function can redirect
   * directly to this URL, and the user will not see any access denied page.
   *  */
  let lastUrlValid = null;
  const lastUrl = window.localStorage.getItem(ORG_LAST_URL);

  // Only use last URL if a valid string
  if (!lastUrlNotEmpty(lastUrl)) {
    console.log('getOrgLastUrl not a valid URL', lastUrl);

  } else {
    // Check access permission for this org, return null if no access
    const orgId = getOrgIdFromUrl(lastUrl);
    if (!orgId) {
      console.log('getOrgLastUrl: there is no orgId in URL', lastUrl);
    } else {
      // Org Id appears to be valid
      try {
        // Check access with org/my-perm/<uuid:org_id> API
        const orgPerm = await getOrgPermBool(orgId);
        if (orgPerm) {
          // org URL is valid for this user
          console.log('getOrgLastUrl, org is valid', orgId);
          // Set the validated URL
          lastUrlValid = lastUrl;
        } else {
          console.log('getOrgLastUrl, user has NO ACCESS to org:', orgId);
        }
      } catch (e) {
        console.error('Error while checking user org permission', e);
      }
    }
  }

  // Always return here. If last URL is not valid, remove from local storage
  if (lastUrlValid === null) {
    console.warn('Removing invalid org_last_url');
    removeOrgLastUrl();
  }
  return lastUrlValid;
}
