/**
 * getOrgDetail.js
 *
 * This is a custom hook for S9, which fetches and keeps up-to-date the user's
 * list of orgs. Using @tanstack/react-query behind the scenes.
 *
 * It is set to networkMode: 'always' to allow the query to run when offline.
 */

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { authRequest } from '../../../api/auth.js';
import { useUsedOrgUUID } from './currentOrgUUID';

export const getOrgDetail = async ({ orgUUID }) => {
  const pathUrl = `/api/access/org/detail/${orgUUID}`;
  try {
    console.debug(`getOrgDetail REQUEST: ${pathUrl}`);
    const response = await authRequest.get(pathUrl);
    const data = response.data;
    console.debug('getOrgDetail RESPONSE:', data);
    return data; // Return the data (array of JSON objects)
  } catch (error) {
    console.error(`Error while fetching data from ${pathUrl}:`, error);
    throw error; // Rethrow error to allow the calling function to handle it
  }
};

export const useOrgDetail = ({
  orgUUID = '',
  select = data => data,
  useErrorBoundary = undefined,
} = {}) => {
  const usedOrgUUID = useUsedOrgUUID(orgUUID);
  console.debug('Current Org UUID', usedOrgUUID);
  const placeholderData = useMemo(() => {
    return {};
  }, []);
  const orgQuery = useQuery({
    queryKey: ['orgDetail', usedOrgUUID],
    queryFn: () => getOrgDetail({ orgUUID: usedOrgUUID }),
    enabled: !!usedOrgUUID && usedOrgUUID !== '', // org uuid が存在するまで待機
    placeholderData,
    select,
    useErrorBoundary,
    networkMode: 'always', // Forces the query to run even offline
  });
  return orgQuery;
};

export const useIsOrgSet = () => {
  return useOrgDetail({
    select: data => {
      return Object.keys(data).length > 0;
    },
  });
};
