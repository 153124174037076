import React, { useMemo, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Required for getting root perm name
import { useSelector } from 'react-redux';
import { useIsCheck } from '../../utils/useParams';

import { ContextMenuContext } from '../../providers/ContextMenuContext';
import { UploadContext } from '../UploadContext';
import { useDropzone } from 'react-dropzone';

import { isMobileOrTablet } from '../../../../utils/detectDevice';
import { getFileUrl } from '../../../../utils/getUrls';
import { isDicom } from '../../../../utils/checkData';
import { useSetSelectData } from '../../utils/useSelectData';
import ContextMenuHandler from '../../../../utils/contextMenuHandler';
import { ActiveRowContext } from '../../utils/aggridUtils';
import { isConferencePage } from '../Conference/StorageConference';
import { useParams } from 'react-router-dom';
import { openUniviewer } from '../../../univiewer/UniversalViewerContainer';

const activeStyle = {
  backgroundColor: '#e5e5ff',
};

function AgGridCellRenderer(props) {
  const history = useHistory();
  const setSelectData = useSetSelectData();
  // console.debug('AgGridCellRenderer', props.data);

  // We need rootPermName and isRootPage to ensure correct options are sent to the
  // API to get perms for each data row, for the context menu
  const rootPermName = useSelector(state => state.storageManager.rootPermName);
  const { isRootPage, isFolderPage } = useIsCheck();

  // Click
  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setSelectData({
      type: props.data.object_type,
      data: props.data,
    });
  };

  // ContextMenu for each row in the table
  const { openContextMenu } = useContext(ContextMenuContext);
  const handleContextMenu = event => {
    setSelectData({
      type: props.data.object_type,
      data: props.data,
    });
    const data = props.data;
    let option = {
      position: {
        left: event.clientX - 2,
        top: event.clientY - 4,
      },
      isDataRow: true,
      // ios長押しの場合はevent.target
      anchorEl: event.currentTarget ? event.currentTarget : event.target,
    };
    if (isRootPage()) {
      console.log('AgGrid handleContextMenu, ROOT PAGE perm', rootPermName);
      // Update options for root page
      option.isRoot = true;
      option.rootPermName = rootPermName;
    } else {
      console.log('AgGrid handleContextMenu, FOLDER');
    }
    openContextMenu(data, option);
  };
  const contextMenuHandler = new ContextMenuHandler(handleContextMenu);

  //
  // Double click (open) a folder
  //

  const { initialOrgId, folderUUID } = useParams();
  const isConference = isConferencePage();
  const openFolder = () => {
    const selectData = {
      type: props.data.object_type,
      data: props.data,
    };
    console.log('setSelectData', setSelectData);
    setSelectData(selectData);

    if (isConference) {
      // If this folder is opened from Conference view, must be a Case Study folder
      console.log(props.data.uuid, 'DOUBLE CLICK openFolder CONFERENCE', window.location.pathname);
      // Use URL params to form the Conference URL
      const caseStudyURL = `/conference/org/${initialOrgId}/event/${folderUUID}/casestudy/${props.data.uuid}`;
      console.log('caseStudyURL', caseStudyURL);
      history.push(caseStudyURL, {
        selectData,
      });
    } else {
      // Go to the folder in File Manager view
      history.push(`/storage/${props.data.uuid}${window.location.search}`, {
        selectData,
      });
    }
  };

  const FOLDER = 'folder';
  const FILE = 'fileimage';

  const openViewer = (object_type) => {
    // Always open in a new tab using window.open()
    var url = `/viewer?url=/api/datamanage/dcm/${object_type}/${props.data.uuid}`;
    if (object_type === FILE){
      url += '&dataType=file';
    }
    window.open(url);
  };

  const openData = () => {
    console.log('openData', props.data);
    switch (props.data.object_type) {
      case FOLDER:
        switch (props.data.foldertype) {
          case 'study':
            openUniviewer(props.data);
            break;
          case 'series':
            openUniviewer(props.data);
            break;
          case 'imgSeries':
            openUniviewer(props.data);
            break;
          default:
            // A regular folder (not a DICOM folder)
            openFolder();
            break;
        }
        break;
      case 'file':
        // Open link in new window
        if (props.data.filetype == 's9/url') {
          window.open(`${props.data.url}`);
        } else {
          switch (props.data.filetype){
            case 'image/jpeg':
              openViewer(FILE);
              break;
            case 'image/png':
              openViewer(FILE);
              break;
            // All other file types
            default:
              window.open(getFileUrl(props.data.filetype, props.data.uuid));
              break;
          }
        }
        break;
      default:
        break;
    }
  };

  /*
  //
  //  upload Drag & Drop
  //
    - 実装の方針
      - isDragActive===true になるのはセル一つだけだが、そのときに全ての行の色をつけたい
      - activeCellに現在のセルの位置を入れて、全てのセルに情報を共有する
      - 他のセルに邪魔されないように activeCell の値を書き換えるのは、isDragActiveが「trueのセル」か「trueだったセル」のみにする
  */
  const { activeCell, setActiveCell } = useContext(ActiveRowContext);
  const { makeOnDrop } = useContext(UploadContext);
  const onDropzone = makeOnDrop(props.data);
  const { getRootProps, isDragActive } = useDropzone({
    disabled:
      props.data.object_type != 'folder' ||
      props.data.foldertype == 'study' ||
      props.data.foldertype == 'series' ||
      props.data.foldertype == 'imgSeries',
    noDragEventsBubbling: true,
    onDrop: onDropzone,
  });

  const style = useMemo(() => {
    return {
      ...(props.rowIndex === activeCell.rowIndex ? activeStyle : {}),
    };
  }, [activeCell.rowIndex, props.rowIndex]);

  // Respond to drag and drop events
  useEffect(() => {
    if (isDragActive) {
      if (activeCell.rowIndex !== props.rowIndex) {
        setActiveCell({ rowIndex: props.rowIndex, colId: props.column.colId });
      }
    } else if (
      activeCell.rowIndex === props.rowIndex &&
      activeCell.colId === props.column.colId
    ) {
      setActiveCell({ rowIndex: -1, colId: '' });
    }
  }, [activeCell, isDragActive, props, setActiveCell]);

  return (
    <div
      {...getRootProps()}
      style={style}
      onClick={
        isMobileOrTablet()
          ? isDicom()
            ? () => openViewer()
            : () => openData()
          : handleClick
      }
      onDoubleClick={e => {
        e.stopPropagation();
        openData();
      }}
      onContextMenu={
        isMobileOrTablet() ? () => {} : contextMenuHandler.onContextMenu
      }
      onTouchStart={contextMenuHandler.onTouchStart}
      onTouchCancel={contextMenuHandler.onTouchCancel}
      onTouchEnd={contextMenuHandler.onTouchEnd}
      onTouchMove={contextMenuHandler.onTouchMove}
      className="ag-cell-renderer"
    >
      {props.coreRenderer ? (
        <props.coreRenderer data={props.data} />
      ) : (
        props.data[props.colDef.field]
      )}
    </div>
  );
}

export default AgGridCellRenderer;
