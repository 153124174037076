import { useCallback, useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authRequest } from '../../../api/auth.js';
import { useUsedFolderUUID } from './currentFolderUUID';
import { useSelector } from 'react-redux';
import { useSetCurrentOrgUUID } from '../utils/useParams.js';

const getFolderInfo = async ({
  folderUUID,
  apiOptions = { noFile: false, noUrl: false, combine: false },
}) => {
  /** Main API call to get data for a given folder from S9 backend */
  console.debug('Main API call, data for a folder', folderUUID);
  let url = `/api/datamanage/info/folder/${folderUUID}`;
  let params = [];
  if (apiOptions.noFile) {
    params.push('noFile=true');
  }
  if (apiOptions.noUrl) {
    params.push('noUrl=true');
  }
  if (apiOptions.combine) {
    params.push('combine=true');
  }
  if (params.length > 0) {
    url += '?' + params.join('&');
  }

  return await authRequest.get(url).then(res => res.data);
};

export const useFolderInfo = ({
  folderUUID = '',
  updateCurrentOrgUUID = true,
  useErrorBoundary = undefined,
  apiOptions = { noFile: false, noUrl: true, combine: true },
} = {}) => {
  /* デフォルトは現在地を取得するオプション   */
  const usedFolderUUID = useUsedFolderUUID(folderUUID);

  const currentOrgUUID = useSelector(
    state => state.storageManager.currentOrgUUID
  );
  const setCurrentOrgUUID = useSetCurrentOrgUUID();

  const _onSuccess = useCallback(
    data => {
      if (updateCurrentOrgUUID) {
        if (data.current_folder.org != currentOrgUUID) {
          if (data.current_folder.org === null) {
            setCurrentOrgUUID('');
          } else {
            setCurrentOrgUUID(data.current_folder.org);
          }
        }
      }
    },
    [currentOrgUUID, setCurrentOrgUUID, updateCurrentOrgUUID]
  );

  return useQuery({
    queryKey: ['folderInfo', usedFolderUUID, apiOptions],
    queryFn: () =>
      getFolderInfo({
        folderUUID: usedFolderUUID,
        apiOptions: apiOptions,
      }),
    enabled: usedFolderUUID !== '', // folder uuid が存在するまで待機
    onSuccess: _onSuccess,
    useErrorBoundary,
    networkMode: 'always', // Forces the query to run even offline
  });
};

export function useFolderInfoInvalidate({
  // デフォルトは現在地を取得するオプション
  folderUUID = '',
  apiOptions = { noFile: false, noUrl: true, combine: true },
} = {}) {
  const queryClient = useQueryClient();
  const usedFolderUUID = useUsedFolderUUID(folderUUID);
  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(['folderInfo', usedFolderUUID, apiOptions]);
  }, [apiOptions, queryClient, usedFolderUUID]);
  return invalidateQueries;
}

export function useFolderInfoReset() {
  const queryClient = useQueryClient();
  const currentFolderUUID = useSelector(
    state => state.storageManager.currentFolderUUID
  );
  const resetQueries = useCallback(
    ({
      folderUUID = '',
      apiOptions = { noFile: false, noUrl: true, combine: true },
    } = {}) => {
      if (folderUUID == '') {
        // 現在地更新
        queryClient.resetQueries(['folderInfo', currentFolderUUID, apiOptions]);
      } else {
        // 指定場所更新
        queryClient.resetQueries(['folderInfo', folderUUID, apiOptions]);
      }
    },
    [currentFolderUUID, queryClient]
  );
  return resetQueries;
}
