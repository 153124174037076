import { datadogRum } from '@datadog/browser-rum';

console.log('datadogRum 環境：', process.env.REACT_APP_ENV);

let applicationId, clientToken, service, env;
switch (process.env.REACT_APP_ENV) {
  case 'development':
    console.log('datadogRum 環境 development');
    applicationId = '59fa6e71-95bb-415e-b836-d7793010e9e0';
    clientToken = 'pub28aabd1ed86c6d43822d3d17ce618c98';
    service = 'mnes-s9-prototype-dev';
    env = 's9-staging';
    break;
  case 'production':
    console.log('datadogRum 環境 production');
    applicationId = 'f2cfa1f3-0778-4f8a-8f43-cec6fce7009d';
    clientToken = 'pub30bf037442dfa6bb9459c9bd297e4d99';
    service = 'mnes-s9-prototype-prd';
    env = 's9-prod';
    break;
  default:
    console.warn('Unknown 環境：', process.env.REACT_APP_ENV);
}

export function startDatadog() {
  if (service) {
    console.log('Start datadogRum on ', service);
    datadogRum.init({
      applicationId: applicationId,
      clientToken: clientToken,
      site: 'ap1.datadoghq.com',
      service: service,
      env: env,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.setUser({
      id: window.localStorage.getItem('username'),
      name: window.localStorage.getItem('username'),
      email: window.localStorage.getItem('email'),
      plan: 'premium',
    });

    datadogRum.startSessionReplayRecording();
  };
}
