/**
 * Component to display a summary of uploaded data, after the upload
 * is complete.
 */

import React from 'react';
import {
  Button, Dialog, Typography, Modal, Paper,
  DialogActions, DialogContent, DialogContentText, DialogTitle,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: theme.spacing(2),
  },
  paper: {
    position: 'relative',
    // width: 600,
    width: '80%',
    maxHeight: '80vh',  // Ensure the modal doesn't overflow the screen height
    overflowY: 'auto',  // Allow the modal to scroll vertically
    backgroundColor: theme.palette.background.paper,
    border: '0px',
    borderRadius: '6px',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 3),
  },
  table: {
    minWidth: 500,  // Minimum width for the table
  },
  button: {
    // margin: theme.spacing(1),
  },
  paragraph: {
    paddingTop: theme.spacing(1),
  }
}));

// const UploadConfirmationModal = ({ open, handleClose, uploadedStudies }) => {
const UploadConfirmationModal = ({ open, handleClose, uploadSummary }) => {
  const classes = useStyles();
  const title = 'アップロードされたデータの確認';

  // if uploadSummary is defined, use it to display the upload result.
  let uploadedStudies = {};
  // if (Object.keys(uploadedStudies).length > 0  && uploadSummary.studies) {
  if (uploadSummary.studies && Object.keys(uploadSummary.studies).length > 0) {
    uploadedStudies = uploadSummary.studies;
    console.log('uploadedStudies Summary:', uploadedStudies);
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
        {/* シリーズ数、インスタンス数が正しいなら、 */}
          <Typography>
            以下のデータはアップロードされました。各スタディがビューアに正しく表示されていることを確認してください。
          </Typography>

          {(uploadSummary.errors > 0) && (
            <div>
              <Typography>エラーが起こったファイル: {uploadSummary.errors}枚</Typography>

              <Typography>Messages: </Typography>
              {(uploadSummary.messages).map((msg, i) => {
                return (
                  <Typography key={i}>{msg.filename}: {msg.error}</Typography>
                );
              })}
            </div>
          )}

          {/* Display Studies in a Table */}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="uploaded studies table">
              <TableHead>
                <TableRow>
                  <TableCell>スタディ名</TableCell>
                  <TableCell align="center">モダリティ</TableCell>
                  <TableCell align="center">シリーズ数</TableCell>
                  <TableCell align="center">インスタンス数</TableCell>
                  <TableCell align="center">ビューワで開く</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {Object.keys(uploadedStudies).map((key, index) => {
                const study = uploadedStudies[key];
                return (
                  <TableRow key={index}>
                    <TableCell>{study.name}</TableCell>
                    <TableCell align="center">{study.modalities}</TableCell>
                    <TableCell align="center">{study.numSeries.size}</TableCell>
                    <TableCell align="center">{study.numInstances}</TableCell>
                    <TableCell align="center">
                      <Button
                        className={classes.button}
                        href={study.url}
                        target="_blank"
                        color="primary"
                        variant="contained"
                      >
                        ビューワで開く
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}

              </TableBody>
            </Table>
          </TableContainer>

          {/* {Object.keys(uploadedStudies).map((key, index) => {
            const study = uploadedStudies[key];
            console.log('Display study summary', study);
            return (
              <div key={index}>
                <Typography className={classes.paragraph}>
                  スタディ: {study.name}
                </Typography>
                <Typography>
                  モダリティ: {study.modalities}
                </Typography>
                <Typography>
                  シリーズ数: {study.numSeries.size}
                </Typography>
                <Typography>
                  インスタンス数: {study.numInstances}
                </Typography>
                <Button
                  className={classes.button}
                  href={study.url}
                  target="_blank"
                  color="primary"
                  variant="contained"
                >ビューワで開く</Button>
              </div>
            );
          })} */}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained">
              閉じる
          </Button>

        </DialogActions>
      </div>

    </Modal>
  );
}

UploadConfirmationModal.defaultProps = {
  uploadedStudies: [],
}

export default UploadConfirmationModal;
