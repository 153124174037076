/**
 * This OrgMenuSecondary component is adapted from
 * Viewers/platform/viewer/src/features/storage/components/TopBar/OrgListButton.js
 * This is the top-right User menu to be displayed in secondary applications, as opposed to
 * the main S9 File Manager. OrgMenuSecondary is displayed in the
 * Conference and Medical Search pages. It may also be used in other connected apps.
 *
 * This component displays a drop-down menu when clicked. Unlike the main OrgListButton,
 * this component's drop-down menu does not have an Organization selector. Users access
 * this page from the main S9 File Manager, when an Org is already selected.
 *
 * The selected Org, if relevant to the current application, may be set in a URL parameter.
 * The URL param is unwrapped in the line const { initialOrgId } = useParams();
 * and if set, may be used elsewhere in this component.
 */

import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// Imports related to Organization
import OrgAvatar from '../organization/components/OrgAvatar';
import { UserContext } from '../../providers/UserContext';
import { useCheckOrgPerm } from '../organization/api/getOrgUserPerm';
import { useOrgDetail } from '../organization/api/getOrgDetail';
import { getOrgDetail } from '../organization/api/getOrgDetail';
import { ModalContext } from '../../context/ModalContext';
import {
  useSetCurrentFolderUUID,
  useSetCurrentOrgUUID,
  useSetRootPermName,
  useSetTutorialId,
  useIsCheck,
} from '../storage/utils/useParams';

import { checkConciergeAccess, getCustomPermApi, getOrgList } from '../../api/rest';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {
  Button, Grid, Popover, Divider, Typography,
  List, ListItem, ListItemText, ListSubheader, Paper
} from '@material-ui/core';
import { isMobile } from '../../utils/detectDevice';
import HelpButton from '../storage/components/Tutorial/HelpButton';
import { TERMS_OF_SERVICE } from '../../components/termsOfService/TermsOfServiceDocument';
import { CONFERENCE_APP_TITLE } from '../storage/components/Conference/ConferenceTitle';
import { MEDICAL_SEARCH_TITLE } from './ConciergeTitle';
import { isConferencePage } from '../storage/components/Conference/StorageConference';

const useStyles = makeStyles(theme => ({
    orgButton: {
      textTransform: 'none',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(0.7)',
      },
    },
    orgButtonText: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 0, 0, 1),
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    orgButtonUsername: {
      fontSize: theme.font.fontSizeBaby,
      color: theme.color.textColorDefault,
    },
    orgButtonOrgName: {
      fontSize: theme.font.fontSizeDefault,
      color: theme.color.textColorDefault,
    },
    orgMenu: {
      Height: 500,
    },
    orgPaper: {
      // maxHeight: 350,
    },
    orgList: {
      width: '45ch',
      backgroundColor: theme.palette.background.paper,
      border: 0,
      borderRadius: 5,
      padding: 0,
      boxShadow: '0 1px 2px 1px rgba(128, 128, 128, .3)',
    },
    connectedAppText: {
      color: theme.palette.primary.main,
      fontWeight: '700',
    },
  })
);

const OrgMenuSecondary = () => {
  const { user, logout, isUserLoggedIn } = useContext(UserContext);
  console.debug('user', user);
  const history = useHistory();

  // Get org ID from URL. Medical search does not have this param.
  const { initialOrgId } = useParams();

  // Menuのstate, handleOpenを呼び出したtargetをセット
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orgsInfo, setOrgsInfo] = React.useState([]);

  // Manage selected org using react-redux
  const { data: orgDetails, isLoading: isLoadingOrgDetail } = useOrgDetail();
  const setCurrentOrgUUID = useSetCurrentOrgUUID();
  const { openSettingMembers } = useContext(ModalContext);
  // Org admin status. Set true if user is an admin of the currently selected org
  const isOrgAdmin = orgDetails.your_perm === "org_admin";
  // Allow selected org to be changed (Disabled in OrgMenuSecondary)
  // const [orgDetails, setOrgDetails] = React.useState({});
  // const [isOrgAdmin, setIsOrgAdmin] = React.useState(false);

  // MNES Admin user flag
  const [isAdmin, setIsAdmin] = React.useState(false);
  // Check perms for connected apps
  const [accessConcierge, setAccessConcierge] = React.useState(false);
  // const isConference = location.pathname.startsWith('/conference/');
  const isConference = isConferencePage();
  console.debug('UserButton initialOrgId', initialOrgId, 'isConference', isConference);
  const isProd = process.env.REACT_APP_ENV === 'production';

  // When component loads, check user permissions (Medical Search, admin access)
  useEffect(() => {

    const fetchOrgList = async () => {
      if (isUserLoggedIn) {

        // console.log(user, 'org Admin', isOrgAdmin, 'orgDetails', orgDetails);
        const response = await getOrgList();
        const data = response.data;
        console.log('Conference fetchOrgList', data);
        // Keep only orgs where the user is Admin (for Conference Edition)
        let orgsWithAdmin = [];
        for (let org of data) {
          if (org.your_perm==="org_admin") {
            orgsWithAdmin.push(org);
          }
        }
        setOrgsInfo(orgsWithAdmin);
        // TODO: add to JSX, adapt from OrgListButton.js
      }
    }

    const checkAccess = async() => {
      // Check access for connected apps
      const status = await checkConciergeAccess();
      if (status === 200) {
        console.log('Medical Search access OK');
        setAccessConcierge(true);
      } else {
        console.log('Medical Search access denied', status);
        setAccessConcierge(false);
      }

      console.log('OrgMenuSecondary getCustomPermApi');
      const customPermResp = await getCustomPermApi();
      const adminStatus = customPermResp.status;
      if (adminStatus === 200) {
        console.log('User is Admin');
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }

    // Check user permissions, and get org list
    fetchOrgList();
    checkAccess();
  }, []);

  // When org ID has been set, fetch org details
  useEffect(() => {
    if (initialOrgId) {
      // Set org details in Redux state
      setCurrentOrgUUID(initialOrgId);
    }
  }, [initialOrgId]);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openToS = () => {
    window.open(TERMS_OF_SERVICE);
  }

  const classes = useStyles();

  return (
    <>
      {/* Org button at top-right */}
      <Button
        className={classes.orgButton}
        size="large"
        onClick={handleOpen}
        endIcon={<ChevronRightIcon />}
      >
      {isUserLoggedIn && user ? (
        <>
          {/* Display the Avatar using Org name if selected, otherwise display user initial */}
          { orgDetails.name ? (
            <OrgAvatar orgName={orgDetails.name} size="large" />
          ) : (
            <OrgAvatar orgName={user.username} size="medium" />
          )}

          <div className={classes.orgButtonText}>
          <div className={classes.orgButtonOrgName}>{orgDetails.name}</div>
            {/* <OrgAvatar orgName={user.username} size="medium" /> */}
            {/* <OrgAvatar orgName={orgDetails.name} size="large" /> */}
            <div className={classes.orgButtonUsername}>
              {user.email}
            </div>
          </div>
        </>
      ) : (
        <div>未ログイン</div>
      )}
      </Button>

      {/* User menu without organization settings.
      This user menu is used on Medical Search and Conference pages,
      not in the main S9 File Manager */}
      {isUserLoggedIn && user ? (
        <Popover
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
        anchorEl={anchorEl}
        className={classes.orgMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        >
        {/* User email is List header */}
        <Paper className={classes.orgPaper}>

          <List
            component="nav"
            subheader={
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <ListSubheader
                  component="div"
                  id="list-org"
                  className={classes.listSubheader}
                >
                  {user.email}
                </ListSubheader>
                {isMobile() && <HelpButton />}
              </Grid>
            }
            className={classes.orgList}
          >
            {isOrgAdmin && (
              // Show Settings & Members for Org Admins
              <div>
                <ListItem
                  button
                  onClick={openSettingMembers}
                  className={classes.button}
                >
                  <SettingsOutlinedIcon />
                  {'Settings & Members'}
                </ListItem>
                <Divider />
              </div>
            )}

            <ListItem
              button
              onClick={openToS}
              className={classes.button}
              disabled={false}
            >
              <AssignmentOutlinedIcon />
              <ListItemText primary={'利用規約'} />
            </ListItem>

            {/* Links to Connected apps are shown here */}
            {/*
              Link back to main S9 File Manager.
              Show only if user is MNES admin (has change_user permission)
              TODO: same for Medical Search
            */}

            {/* {(!isConference || isOrgAdmin) && ( */}
            {isAdmin && (
              <div>
                <ListItem
                  button
                  onClick={async () => {
                    window.open('/storage');
                  }}
                >
                  <Typography className={classes.connectedAppText}>
                    S9 Mercury α版
                  </Typography>
                </ListItem>
                <Divider />
              </div>
            )}

            {/*
              Link to Medical Search, only for:
              - Admins
              - with medsearch permission
              - not on production (Medical Search only exists on Staging)
            */}
            {(accessConcierge && isAdmin && !isProd ) && (
              // Medical Search (旧Concierge) page
              <div>
                <ListItem
                  button
                  onClick={async () => {
                    window.open('/medical-search');
                  }}
                >
                  <Typography className={classes.connectedAppText}>
                    {MEDICAL_SEARCH_TITLE}
                  </Typography>
                </ListItem>
                <Divider />
              </div>
            )}

            {/*
              Conference Page, show when:
              - not already on a Conference page
              - user is Admin of selected org
            */}
            {(isOrgAdmin && !isConference) && (
              <div>
                <ListItem
                  button
                  onClick={async () => {
                    window.open('/conference/org/' + orgDetails.uuid);
                  }}
                >
                  <Typography className={classes.connectedAppText}>
                    { CONFERENCE_APP_TITLE }
                  </Typography>
                </ListItem>
                <Divider />
              </div>
            )}

              <ListItem
                button
                onClick={async () => {
                  // Set continueUrl, user will be taken to this page on next login
                  let loginUrl = '/login';
                  if (location.pathname.startsWith('/conference')) {
                    loginUrl += `?continueUrl=${location.pathname}`;
                  } else if (location.pathname.startsWith('/medical-search')) {
                    loginUrl += `?continueUrl=/medical-search`;
                  }
                  await logout();
                  history.push(loginUrl);
                }}
              >
                <ListItemText primary={'ログアウト'} />
              </ListItem>

          </List>
        </Paper>
        </Popover>
      ) : (
        <div>未ログイン</div>
      )}

    </>
  )
}

export default OrgMenuSecondary;
